.app {
  background: #F2F3F5;
  min-height: 100vh;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

.app-link {
  color: #61dafb;
}

.arco-nav-bar.arco-nav-bar-fixed,
.arco-nav-bar .arco-nav-bar-wrapper {
  height: 1.173333rem !important;
}

.arco-nav-bar .arco-nav-bar-title-text {
  font-size: 0.4rem;
  font-weight: 500;
}

.arco-nav-bar .arco-nav-bar-left {
  font-size: 0.4rem;
}

.arco-nav-bar .arco-nav-bar-left .nav-bar-back {
  height: 0.48rem;
}

.arco-tabs.tabs {
  color: #EFEFEF;
  width: 70%;
  margin-left: 0.266667rem;
}

.arco-tabs.tabs .arco-tab-cell-container-wrap {
  background: none;
  font-size: 0.373333rem;
}

.arco-tabs.tabs .arco-tab-cell.vertical:not(.custom).line.active,
.arco-tabs.tabs .arco-tab-cell.vertical:not(.custom).line-divide.active {
  color: #FFFFFF;
  font-size: 0.48rem;
}

.arco-tabs.tabs .pos-top .arco-tab-cell-underline {
  height: 0.106667rem;
  width: 0.4rem;
}

.arco-tabs.tabs .arco-tab-cell-underline-inner {
  width: 0.4rem;
  height: 0.106667rem;
  background: #FFFFFF;
  border-radius: 0.053333rem;
}

.cardContent .arco-grid-rows-item-title {
  font-size: 0.346667rem;
  color: #000000;
}

.footerBox {
  background: #fff;
  z-index: 111;
}

.arco-popup-content.popBox {
  border-radius: 0.746667rem 0.746667rem 0 0;
}

.arco-popup-content.popBox .popupTitle {
  font-size: 0.426667rem;
  font-weight: 500;
  text-align: center;
  margin: 0.533333rem;
  position: relative;
}

.arco-popup-content.popBox .popupTitle .close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.533333rem;
}

.arco-popup-content.popBox .popupContent {
  padding-bottom: 0.533333rem;
}

.arco-popup-content.popRightBox .popupTitle {
  font-size: 0.426667rem;
  font-weight: 500;
  text-align: center;
  margin: 0.533333rem;
  position: relative;
}

.arco-popup-content.popRightBox .popupTitle .close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.533333rem;
}

.arco-popup-content.popRightBox .popupContent {
  min-width: 8.533333rem;
  padding-bottom: 0.533333rem;
}